<template>
  <base-layout>
    <!--header-bar></header-bar-->
    <ion-searchbar
        show-cancel-button="focus"
        @ionChange="filter(search)"
        :value="search" placeholder="Suche"
        @ionInput="search = $event.target.value;"
    ></ion-searchbar>
    <IonSegment :value="activeDay" scrollable style="justify-content: flex-start">

      <IonSegmentButton style="min-width:50px; width: 50px;max-width:50px" @click="changeToGrid()">
        <ion-icon :icon="gridOutline"></ion-icon>
      </IonSegmentButton>
      <IonSegmentButton v-for="(value, key) in days" :key="key" :value="key" style="min-width:250px" mode="md"
                        @click="activeDay = key">{{ value.label }}
      </IonSegmentButton>
    </IonSegment>
    <div style="display: flex; margin:10px 5px">
      <div style="margin-right: 5px"><font-awesome-icon :icon="['fas', 'camera-movie']" class="type-icon" style="color:grey;font-size:1.2rem;margin:0 0 0 5px;" /> &nbsp;= wird gestreamt/aufgezeichnet</div>
      <!--div><font-awesome-icon :icon="['fas', 'file-video']" class="type-icon" style="color:grey;font-size:1.2rem;margin:0 0 0 5px;" /> &nbsp;= als Aufzeichnung verfügbar</div-->
      <!--font-awesome-icon :icon="['fas', 'file-pdf']" class="type-icon"/-->
    </div>

    <ion-content>

      <ion-list class="wcpt-session-list" v-if="activeDay && days[activeDay]">
        <div v-for="session in days[activeDay].sessions" :key="session.id">
          <a :href="session.session_type_text != 'Break' ? '#/app/sessions/' + session.id : ''"
             v-if="!session.isHeader"
             :class="{'wcpt-session-item' : session.session_type_text != 'Break', 'wcpt-session-item-break' :session.session_type_text == 'Break'}"
             :style="{border:'4px solid ' +  session.color} ">

            <!--img class="picture" src="img/avatar.png" ng-if="!session.chair[0] || !session.chair[0].vitae_picture">
            <img class="picture" ng-src="https://fileserver-188ab.kxcdn.com/file/{{session.chair[0].vitae_picture.split('/')[4]}}" ng-if="session.chair[0] && session.chair[0].vitae_picture"-->
            <div class="head">
              <div class="type">{{ session.session_type_text }}</div>
              <div class="type-icons" v-if="session.session_type_text != 'Industriesymposium'">
                <font-awesome-icon :icon="['fas', 'camera-movie']" class="type-icon" v-if="session.is_stream || session.is_recording"/>
                <!--font-awesome-icon :icon="['fas', 'file-pdf']" class="type-icon"/-->
              </div>

              <div class="time" v-if="session.session_type_text != 'Break'">
                <span style="color:gray; font-size: 12px">
          {{ moment(session.start_time).format('dddd HH:mm') }} -
                  {{ moment(session.end_time).format('HH:mm') }} <span class="" translate="UHR"></span></span>
              </div>
              <div class="time" v-if="session.session_type_text == 'Break'"><span style="color:gray; font-size: 12px">
          {{ moment(session.start_time).format('dddd HH:mm') }} -
                  {{ moment(session.end_time).format('HH:mm') }} <span class="" translate="UHR"></span></span>
              </div>
              <!--div class="code" ng-if="session.code">[{{session.code}}]</div-->
            </div>
            <div class="content">
              <div class="title">{{ session.title }}</div>
              <div class="chair" v-if="session.chair && session.chair.length > 0"><strong>Vorsitz:</strong> <span
                  v-for="chair in session.chair" :key="chair.id">{{ chair.display_name }}</span></div>
              <div class="speaker" v-if="session.speaker && session.speakers.length > 0"><strong>Referierende:</strong> <span
                  v-for="speaker in session.speakers" :key="speaker.id">{{ speaker.first_name }} {{ speaker.last_name }}</span></div>

            </div>

            <div class="indicator" v-if="activeSessions.indexOf(session.id) != -1">[ LIVE ]</div>
            <!--div class="indicator" style="color:black;font-size:2.4rem" v-if="isOnDemandAvailable(session)"><i
                class="icon ion-ios-videocam"></i></div-->
            <div class="shape" :style="{background:session.color}">
              <div class="room" :style="{color:session.font_color}"><p class="channel">{{ session.location.name }}</p>
              </div>
            </div>
          </a>


          <div v-if="session.isHeader" class="item item-divider" style="width:100vw; padding-left:20px">
            <h2> {{ moment(session.start_time).format('HH:mm') }} </h2>
          </div>

        </div>
      </ion-list>

    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonList, IonSearchbar, IonSegment, IonSegmentButton, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';

moment.locale("de");

import {gridOutline} from 'ionicons/icons';

const config = require('@/config');

export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      freeSymps: ['da7ad0c0-3ed1-4500-1345-010000000025', 'da7ad0c0-3ed1-4500-1345-010000000044', 'da7ad0c0-3ed1-4500-1345-010000000027', 'da7ad0c0-3ed1-4500-1345-010000000065', 'da7ad0c0-3ed1-4500-1345-010000000057', 'da7ad0c0-3ed1-4500-1345-010000000026']
    }
  },

  setup() {
    return {
      gridOutline,
    }
  },
  components: {
    IonContent,
    IonList,
    IonSegment,
    IonSegmentButton,
    IonSearchbar
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    ...mapGetters('auth', ['user']),
    ...mapState('sessions', ['activeSessions']),
    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionListLive']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    openMenu() {
      menuController.open("app-menu")
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.days = {};
        this.prepareDays(this.filtered);
      } else {
        this.filtered = [];
        this.resetList();
      }
    },
    resetList() {
      this.days = {};
      this.prepareDays(this.sessions);
    },
    async fetchSessions() {
      this.sessions = (await this.getSessionListLive()).sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });

      this.sessions = this.groupList(this.sessions, 'start_time');
      this.days = {};
      this.prepareDays(this.sessions);
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHeader: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, D. MMMM')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },

    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToGrid() {
      this.$router.push('/app/gridList');
    },

    isAllowed() {
      if (this.user && this.user.extra && this.user.extra[config.conference]) {
        return true;
      } else {
        return true;
      }
    },

    async raiseNotAllowed() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Info',
            message: 'Ihre Registrierung enthält keinen Zugriff auf diese Ressource',
            buttons: [
              {
                text: 'OK',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
            ],
          });
      return alert.present();
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        //let disclaimer = session.disclaimer.replaceAll('&lt;br&gt;', '<br>');
        let disclaimer = session.disclaimer.replace(/&lt;br&gt;/g, '<br>');
        console.log('replaced disclaimer', disclaimer)

        if ((!this.user && this.freeSymps.indexOf(session.id) != -1) || this.user) {
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: 'Information',
                message: '<strong>' + disclaimer + '</strong>',
                buttons: [
                  {
                    text: 'Nein',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: blah => {
                      console.log('Confirm Cancel:', blah);
                      this.$router.push('/app/sessions/' + session.id);
                    },
                  },
                  {
                    text: 'Ja',
                    id: 'confirm-button',
                    handler: () => {

                      if (this.user) {
                        let trackData = {
                          user: this.user,
                          item: session,
                          event: 'share',
                          type: 'session',
                          itemId: session.id,
                        }
                        this.trackEvent(trackData)
                        this.uploadTrackingEvents();

                        this.trackWithLabel(session.title);
                        this.$router.push('/app/sessions/' + session.id);
                      } else {
                        this.showUserPrompt(session);
                      }


                    },
                  },
                ],
              });
          return alert.present();
        } else {
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: 'Keine Berechtigung',
                message: '<strong>Sie haben keine Berechtigung diesen Bereich zu sehen</strong>',
                buttons: [
                  {
                    text: 'OK',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: blah => {
                      console.log('Confirm Cancel:', blah);
                    },
                  },
                ],
              });
          return alert.present();
        }


      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    },
    async showUserPrompt(session) {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Information',
            message: '<strong>Bitte geben Sie Ihre Kontaktdaten ein</strong>',
            inputs: [
              {
                name: 'firstName',
                placeholder: 'Vorname',

              },
              {
                name: 'lastName',
                placeholder: 'Nachname',

              },
              {
                name: 'email',
                placeholder: 'Email',

              },
            ],
            buttons: [
              {
                text: 'Nein',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah);
                  this.$router.push('/app/sessions/' + session.id);
                },
              },
              {
                text: 'Ja',
                id: 'confirm-button',
                handler: (data) => {
                  console.log(data)
                  let trackData = {
                    user: data,
                    item: session,
                    event: 'share',
                    type: 'session',
                    itemId: session.id,
                  }
                  this.trackEvent(trackData)
                  this.uploadTrackingEvents();

                  this.trackWithLabel(session.title);
                  this.$router.push('/app/sessions/' + session.id);
                },
              },
            ],
          });
      return alert.present();

    }


  },
  async created() {
    this.fetchSessions();
    //this.activeDay = this.$route.params.day;
    this.moment = moment;
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions()
      }
    },
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {

        if (to.name == 'SessionListDay') {

          if(this.$route.params.day){
            this.activeDay = this.$route.params.day
          }else{
            this.activeDay = localStorage.getItem('lastViewedDay');
          }
          this.moment = moment;
          this.fetchSessions();
        }

        if (to.name == 'SessionList') {

          this.activeDay = localStorage.getItem('lastViewedDay') || this.$route.params.day;
          this.moment = moment;
          this.fetchSessions();
        }else{
          if(this.activeDay){
            localStorage.setItem('lastViewedDay', this.activeDay)
          }
        }
      }
    },

  },
});
</script>
<style lang="scss">
.my-custom-class {
  input[type=text], input[type=password] {
    border-radius: 0;
    padding: 10px;
    background-color: #ffffff;
    border: 0.5px solid #c9c9c9;
    color: black;
  }
}
</style>
